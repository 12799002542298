@import '../../styles/const';

.office-tree {
  &.wrapper {
    display: flex;
    flex-direction: column;
    grid-row-gap: 24px;
    padding: 24px 40px;
    max-height: 400px;
    width: 570px;

    @media screen and (max-width: $md) {
      padding: 24px 15px;
    }
  }

  &__loader {
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: center;
  }

  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .active-item {
    .icon-open {
      display: block;
    }
    .icon-close {
      display: none;
    }
  }

  .active  {
    display: block;
  }

  &__offices {
    overflow: auto;
  }

  &__item {
    padding: 8px 0;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__buildings {
    display: none;
    overflow: hidden;
    padding: 8px 0 8px 16px;

  }
  &__floors {
    display: none;
    overflow: hidden;
    padding: 8px 15px 8px 20px;
  }

  &__name {
    font-size: 18px;
    grid-column-gap: 5px;
    color: #191919;
  }

  &__floor-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .icon-open {
    display: none;
    width: 20px;
    height: 20px;
    margin-left: -4px;
    margin-right: 5px;
  }

  .icon-close {
    margin-right: 10px;
  }
}
