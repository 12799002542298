@import '../../styles/const';

.device-types {
  &.wrapper {
    display: flex;
    flex-direction: column;
    grid-row-gap: 24px;
    padding: 24px 40px;

    @media screen and (max-width: $md) {
      padding: 0;
    }
  }

  &__devices {
    display: grid;
    grid-gap: 12px;


    &__type {
      position: relative;
      display: flex;
      align-items: center;
      grid-gap: 16px;
      padding: 12px 24px;
      cursor: pointer;

      background: #ffffff;
      border: 2px solid #E4ECEF;
      box-shadow: 0 4.278186798095703px 18.53881072998047px 0px rgba(60, 68, 110, 0.10);
      border-radius: 16px;

      @media screen and (max-width: $md) {
        padding: 10px;
        grid-gap: 14px;
      }

      &.active {
        border: 2px solid #3c7bff;
        box-shadow: 0 4px 20px rgba(47, 64, 151, 0.3);
      }

      &__ok-icon {
        position: absolute;
        top: -16px;
        right: -16px;
        display: none;

        &.active {
          display: block;
        }
      }

      &__description {
        width: 90%;
        @media screen and (max-width: $lg) {
          width: 80%;
        }
        @media screen and (max-width: $sm) {
          width: 75%;
        }
      }

      &__image {
        width: 11%;
        text-align: center;
        @media screen and (max-width: $lg) {
          width: 20%;
        }
        @media screen and (max-width: $sm) {
          width: 25%;
        }

        &--phone {
          img {
            max-height: 100px;
          }
        }
      }

      &__reception {
        img {
          height: 70px;
        }
      }

      &__name {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        text-transform: capitalize;
        color: #191919;
        margin-bottom: 10px;

        &--private {
          margin-bottom: 15px;
        }
      }
      &__text {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
      }
    }
  }
}
